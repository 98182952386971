.playmoment-calendar {

  .organisation-lookup {
    .title {
      font-size: 1rem;
      margin-right: 16px;
    }

    padding-top: 24px;
    margin-left: 16px;
    //margin-bottom: 16px;
  }


  .calender-header {
    display: flex;
    flex-direction: row;
    padding-top: 24px;
    margin-left: 16px;
    margin-bottom: 16px;

    .date-title {
      min-width: 140px;
      margin-right: 16px;
      font-size: 18px;
      font-weight: bold;
      // padding-right: 16px;
      // background-color: red;
    }

    .previous-btn {
      margin-right: 16px;
    }

    .next-btn {
      margin-right: 16px;
    }

  }

  // .entered {
  //   .add {
  //     opacity: 1;
  //   }
   
  // }

 // .ant-picker-cell {
    //  .add {
    //   opacity: 0.2;
    //  }
  //}

  .day-cell {
    //  border-color: red;
    //  border-width: 1px;
    //  border-style: solid;

    .add {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    } 

    .entries-container {

      .entry-item {

        .entry-tag {
          display: inline-block;
          padding-right: 8px;
          padding-left: 6px;
          border-radius: 6px;
          //margin-bottom: 4px;

          .tag-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .icon-container {
              display: flex;
              flex-direction: row;
            }

            .edit-icon {
              color: white;
              padding-right: 8px;
              padding-left: 4px;
            }

            .close-icon {
              font-size: 12px;
              color: white;
            }

            .tag-title {
              color: white;
              margin-top: 0px;
              margin-bottom: 0px;
            }

          }

        }

        .entry-title {
          display: inline;
        }
      }
    }

  }

}