
.alertContainer {
    margin-bottom: 24px;
}

.primarySectionsContainer {
    display: flex;
    column-gap: 24px;
    margin-bottom: 16px;
}

.selectPrimarySection {
    flex: 1
}

.primaryOrganisationSelect {
    margin-top: 8px;
    margin-bottom: 24px;
}

.activitiesSectionsContainer {
    display: flex;
    column-gap: 24px;
    margin-bottom: 16px;
}

.selectActivitiesSection {
    flex: 1
}

.container {
    //background-color: red;
    display: flex;
    column-gap: 24px;
    margin-bottom: 32px;
    //justify-content: space-evenly;
}

.selectCol {
    //background-color: green;
    flex: 1;
    //padding-right: 16px
}

.selectedCol {
   // background-color: blue;
    flex: 1,
}

.organisationSelect {
    min-width: 320px;
}

.activitiesSelectContainer {
    margin-top: 24px;
}

.itemPrimaryContainer {
    display: flex;
    column-gap: 16px;
}

.currentBadge {
    margin-left: 8px;
}

.associatedTag {
    display: flex;
    column-gap: 16px;
}