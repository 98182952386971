.settings-theme {

    .list-component {
        background-color: #fff;
        padding-bottom: 16px;
    }

    .item-card {

        .item-content {
            display: flex;
            flex-direction: row;

            .item-container {
                display: flex;
                flex-direction: row;
                //justify-content: space-between;
                align-items: center;

                .drag-handle {
                    padding-right: 16px;
                }

                .image-container {
                    min-height: 160px;
                    min-width: 280px;
                }
            }

            .item-right {
                padding-left: 48px;

                .visiblility-status {
                    display: flex;
                    flex-direction: column;

                    .toggle-switch {
                        margin-top: 8px;
                        max-width: 48px;
                    }
                }

                .date-range-section {
                    padding-top: 16px;
                    display: flex;
                    flex-direction: row;

                    .date-item {
                        padding-right: 16px;
                        display: flex;
                        flex-direction: column;
                    }

                    .date-text {
                        margin-top: 8px;
                        text-align: center;
                    }
                }

                .date-range-edit {
                    padding-top: 8px;
                }

                .date-range-toggle {
                    display: flex;
                    flex-direction: column;
                    padding-top: 16px;

                    .toggle-switch {
                        margin-top: 8px;
                        max-width: 48px;
                    }

                    .invalid-date-warning {
                        padding-left: 8px;
                    }
                }

            }

        }

        .theme-image {
            min-height: 160px;
            min-width: 200px;
        }

        .bottom-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .color-tag {
                border-radius: 4px;
            }
        }

        .actions {
            margin-top: 16px;

            .disable {
                color: grey;
                cursor: not-allowed;
            }

            .edit {
                padding-right: 16px;
            }
        }
    }


}