

.challenge-details-section {

 .title-section {
    margin-top: 16px;
 }

 .publish-section {
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    .publish {
        .toggle-publish {
            margin-left: 16px;
        }
    }

    .webview {
        margin-left: 24px;
    }

 }

 .daily-activity-section {
    margin-top: 16px;

    .toggle-switch {
        margin-left: 16px;
    }
 }

 .ant-tabs-nav-operations {
    display: none !important;
    }

}