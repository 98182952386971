.dash-home {

    .user-charts-container {
        //display: flex;
        //flex-direction: row;
        //height: 300px;
        //flex-wrap: wrap;

        .user-chart-container {

            //background-color: red;
            .title-container {
                text-align: center;

                .title {
                    font-size: 1.2rem;
                    font-weight: 600;
                }
            }

            .user-pie-chart {
                display: flex;
                flex-direction: row;
                height: 324px;
                padding-bottom: 24px;

                .custom-legand {

                    .footer {
                        text-align: center;
                        padding-top: 16px;
                        font-size: 1rem;
                        font-weight: 600;
                    }
                }

              
            }

        }





    }

    .one-users {

        margin-top: 48px;

        .users-chart-container {

            .title-container {
                text-align: center;

                .user-link {
                    margin-left: 16px;
                }

                .title {
                    font-size: 1.2rem;
                    font-weight: 600;
                }
            }

            .composite-chart {
                height: 480px;

                .user-tooltip {
                    //background-color: red;
                    background-color: rgb(222, 222, 222);
                    border-color: rgb(243, 243, 243);
                    border-style: solid;
                    border-radius: 4px;
                    border-width: 1px;
                    padding: 16px;

                    .intro {
                        font-size: 1.1rem;
                        font-weight: 500;
                    }
                }
            }

        }
    }

    .email-overview {

        margin-top: 48px;

        .chart-container {
            .email-pie-chart {
                height: 300px;
            }
        }

        .title-container {
            text-align: center;

            .title {
                font-size: 1.2rem;
                font-weight: 600;
            }
        }
    }

    .sent-email {

        padding-top: 24px;

        .sent-emails-bar-chart-container {
            .email-bar-chart {
                height: 600px;
                display: flex;
                flex-direction: row;
                justify-content: center;
            }

            .title-container {
                text-align: center;

                .title {
                    font-size: 1.2rem;
                    font-weight: 600;
                }
            }

        }
    }

}