.prompt-collapse {
    margin-bottom: 16px;
    margin-right: 24px;
}

.prompt-container {

.generated-challenge-details {
    padding-top: 32px;

    .steps-container {

        .edit-icon-container {
            margin-left: 4px;
            color: black;
        }

        .delete-icon-container {
            margin-left: 8px;
            color: red;
        }

        .step-drag-handle {
            padding: 8px
        }

    }

    .edit-item-tag-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 12px;
        row-gap: 12px;
        padding-bottom: 12px;

        .edit-item-tag {
            border-width: 1px;
            border-style: solid;
            border-radius: 12px;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 2px;
            padding-bottom: 2px;
            max-height: 28px;
            font-size: 12px;

        }

        .edit-item-text {
            color: #096dd9;
        }

        .ant-tag-blue {
            color: #096dd9;
            background: #e6f7ff;
            border-color: #91d5ff;
        }

        .edit-icon-container {
            margin-left: 4px;
            color: black;
        }

        .delete-icon-container {
            margin-left: 8px;
            color: black;
        }

        .replace-icon-container {
            margin-left: 8px;
            color: black;
        }


    }



}

.prompt-materials-container {

    .prompt-materials-dropdown {
        min-width: 240px;
    }
}

.agegroups-container {
    margin-top: 18px;

 
}

.agegroup-mergefield {
    margin-left: 12px;
}

}

.replace-material-modal {

    .materials-select-label {
        padding-right: 8px;
    }

    .replace-materials-dropdown {
        min-width: 320px;
    }

}