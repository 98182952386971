@import url('https://fonts.googleapis.com/css2?family=Andika:ital,wght@0,400;0,700;1,400;1,700&display=swap');


/* main banner component */

.one-admin .challenge-banner {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 18px;
}

.one-admin .challenge-banner .values-col {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
}

.one-admin .challenge-banner .areas-col {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
}


/* end main banner container */

/* main banner */

.one-admin .banner-container {
    position: relative;
    width: 280px;
    height: 180px;
    border-radius: 8px;
    border-width: 1px;
    border-color: rgba(236, 232, 232, 0.5);
    border-style: solid;
}

.one-admin .banner-container .banner{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    /* border-width: 1px;
    border-color: red;
    border-style: solid; */
}

.one-admin .banner-container .banner-center {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* border-width: 1px;
    border-color: red;
    border-style: solid; */
}

/* .one-admin .banner-container .title {

    text-align: center;
    padding-right: 16px;
    padding-left: 16px;
    font-weight: 800;
    line-height: 1rem;
    color: #fff;
} */

.one-admin .banner-container .character-container {
    display: flex;
    justify-content: flex-end;
}

.one-admin .banner-container .character-container .character {
    height: 100px;
    width: 100px;
    margin: 8px;
    /* border-width: 1px;
    border-color: green;
    border-style: solid; */
}

.one-admin .banner-container .default-banner-image {
    background-color: rgb(187, 183, 183);
    height: 100%;
    width: 100%;
    border-radius: 8px;
    /* border-width: 1px;
    border-color: grey;
    border-style: solid; */
}

.one-admin .banner-container .banner-image {
    height: 100%;
    width: 100%;
    border-radius: 8px;

    /* border-width: 1px;
    border-color: grey;
    border-style: solid; */
}

/* end main banner*/



/* list banner */
.one-admin .list-banner-container {
    position: relative;
    width: 125px;
    height: 180px;
    border-radius: 16px;

    border-width: 1px;
    border-color: rgba(236, 232, 232, 0.5);
    border-style: solid;
}

.one-admin .list-banner-container .banner{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    /* border-width: 1px;
    border-color: red;
    border-style: solid; */
}

.one-admin .list-banner-container .banner-center {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* border-width: 1px;
    border-color: red;
    border-style: solid; */
}

.one-admin .list-banner-container .title {

    text-align: center;
    font-weight: 800;
    color: #fff;
    line-height: 1rem;
    font-size: 0.7rem;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 8px;
    padding-left: 8px;
    font-family: 'Andika';
    background: rgba(172, 168, 168, 0.3);
    /* border-width: 1px;
    border-color: green;
    border-style: solid; */
}

.one-admin .list-banner-container .character-container {
    display: flex;
    justify-content: flex-end;

    /* border-width: 1px;
    border-color: green;
    border-style: solid; */
}

.one-admin .list-banner-container .character-container .character {
    height: 68px;
    width: 68px;
    margin: 8px;
    margin-right: 4;
    margin-top: 2px;
    /* border-width: 1px;
    border-color: green;
    border-style: solid; */
}

.one-admin .list-banner-container .default-list-banner-image {
    
        background-color: rgb(187, 183, 183);
        height: 100%;
        width: 100%;
        border-radius: 16px;
    
        /* border-width: 1px;
        border-color: grey;
        border-style: solid; */
    }

    .one-admin .list-banner-container .list-banner-image {
        height: 100%;
        width: 100%;
        border-radius: 16px;
        /* border-width: 1px;
        border-color: grey;
        border-style: solid; */
    }

/* end list banner */

.one-admin .values-entries {
    margin-right: 16px;
}

.one-admin .areas-entries {
    margin-left: 16px;
}


/**/
.one-admin .banner-view {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  /* border-width: 1px;
        border-color: grey;
        border-style: solid; */
}

.one-admin .values-container {
    display: flex;
    flex-direction: column;
    /* border-width: 1px;
    border-color: grey;
    border-style: solid; */
}

.one-admin .values-container .value-item {
    border-radius: 8px;
    border-width: 1px;
    border-color: rgb(235, 230, 230);
    border-style: solid;
    margin-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
}

.one-admin .values-container .values-title {
    font-weight: 800;
    font-size: 1.2rem;
    margin-top: 24px;
}

.one-admin .areas-container {
    display: flex;
    flex-direction: column;
    /* border-width: 1px;
        border-color: grey;
        border-style: solid; */
}

.one-admin .areas-container .areas-item {
    border-radius: 8px;
    border-width: 1px;
    border-color: rgb(235, 230, 230);
    border-style: solid;
    margin-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
}

.one-admin .areas-container .areas-title {
    font-weight: 800;
    font-size: 1.2rem;
    margin-top: 24px;
}

/**/