.send-email-list-table {
   .edit-email-draft {
      margin-left: 12px;
   }
}


.send-email-form-container {

   .select-receipients-container {
      margin-bottom: 12px;
   }

   .segmented-filters {
      margin-bottom: 8px;
   }

   .email-subject-div {
      margin-top: 12px;
   }

   .btn-container {
      display: flex;
      flex-direction: row;

      .preview-only {
         margin-right: 8px;
         //margin: 12px;
      }

      .save-draft-btn {
         margin-left: 8px;
      }
   }

   .recepient-action {

      display: flex;
      flex-direction: row;
      margin-bottom: 24px;

      .add-entire-list {
         margin-right: 16px;
      }

   }

}


.send-test-email {
   display: inline-flex;
   column-gap: 12px;
   //flex-direction: row;
}



.banner-image-select-container {
   display: flex;
   flex-direction: column;
   margin-bottom: 8px;

   .select-image-label {
      margin-bottom: 8px;
   }

   .banner-image-url-select {
      margin-bottom: 16px;
   }
}



.section-container {

   .add-section-btn {
      margin-top: 12px;
   }

   // section item
   .section-item-container {
      display: flex;
      flex-direction: column;

      .section-image-select-container {
         flex: 1;
         display: flex;
         flex-direction: column;
         margin-bottom: 8px;

         .section-image-url-select {
            flex: 1;
         }

         .select-image-label {
            margin-bottom: 8px;
         }
      }

      .para-item {
         display: flex;
         flex-direction: row;
         align-items: center;
         margin-bottom: 8px;

         //    border-width: 1px;
         //    border-style: solid;
         //    border-color: red;

         .delete-btn {
            margin-right: 8px;
         }

         .para-text-input {
            flex: 1;
         }

      }

      .link-item {
         display: flex;
         flex-direction: row;
         align-items: center;
         margin-bottom: 8px;
         margin-top: 8px;

         //    border-width: 1px;
         //    border-style: solid;
         //    border-color: red;

         .delete-btn {
            margin-right: 8px;
         }

         .link-card {
            flex: 1;
            margin-bottom: 8px;
         }

      }

      .select-color-container {
         display: flex;
         flex-direction: row;

         .text-input {
            margin-top: 32px;
            margin-left: 12px;
            flex: 1
         }
      }

   }

   .color-picker-placeholder {
      height: 42px;
      width: 42px;
      border-radius: 8px;
   }

}

.emails-list-container {

   .unsubscribed-link {
      margin-top: 4px;
      margin-right: 16px;
   }

}